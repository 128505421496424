import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SimpleExpiry, IdleExpiry, Idle } from '@ng-idle/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthorizationGuard } from './services/authorization-guard';
import { CustomErrorHandler } from './services/custom-error-handler';
import { routing } from './app.routes';
import { AutoSelectDirective } from './form/validation/autoselect.directive';
import { CheckboxValidatorDirective } from './form/validation/checkbox-required.directive';
import { ComparisonValidatorDirective } from './form/validation/comparison-validator.directive';
import { EmailValidatorDirective } from './form/validation/email-validator.directive';
import { DateRangeValidatorDirective } from './form/validation/date-range-validator.directive';
import { NumberValidatorDirective } from './form/validation/number-validator.directive';
import { PasswordValidatorDirective } from './form/validation/password-validator.directive';
import { NoDuplicatesValidatorDirective } from './form/validation/no-duplicates.directive';
import { AppComponent } from './app.component';
import { AddressComponent } from './form/reusables/address/address.component';
import { AutocompleteComponent } from './form/reusables/autocomplete/autocomplete.component';
import { ChangeApplicationTypeComponent } from './form/reusables/change-application-type.component';
import { CombinedAddressComponent } from './form/reusables/address/combined-address.component';
import { ComplexQuestionComponent } from './form/reusables/wizard/complex-question.component';
import { ContactComponent } from './form/reusables/contact-information.component';
import { ContactPageComponent } from './contact/contact-page.component';
import { CorporationComponent } from './form/reusables/autocomplete/corporation.component';
import { CreditRequirements } from './form/reusables/credit-requirements.component';
import { DateBoxComponent } from './form/reusables/date/date-box.component';
import { FooterComponent } from './form/reusables/view/footer.component';
import { HeaderComponent } from './form/reusables/view/header.component';
import { FullNameComponent } from './form/reusables/fullname.component';
import { LoginComponent } from './form/reusables/account/login.component';
import { PasswordExpiredComponent } from './form/reusables/account/password-expired.component';
import { PhoneComponent } from './form/reusables/phone/phone.component';
import { ReCaptchaComponent } from './form/reusables/recaptcha.component';
import { ResetPasswordComponent } from './form/reusables/account/reset-password.component';
import { SecurityQuestionComponent } from './form/reusables/account/security-question.component';
import { SideNavComponent } from './form/reusables/view/side-nav.component';
import { SimpleQuestionComponent } from './form/reusables/wizard/simple-question.component';
import { StateComponent } from './form/reusables/address/states.component';
import { ZipComponent } from './form/reusables/address/zip.component';
import { ForceDecimalsDirective } from './form/validation/force-decimals.directive';
import { AttachmentsComponent } from './form/reusables/attachments/attachments.component';
import { AutofocusDirective } from './form/reusables/auto-focus/auto-focus.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationProfileComponent } from './registration/profile/registration-profile.component';
import { RegistrationLinkAccountComponent } from './registration/profile/registration-linkaccount.component';
import { RegistrationNavigationComponent } from './registration/navigation/registration-navigation.component';
import { RegistrationAgreementComponent } from './registration/agreement/registration-agreement.component';
import { RegistrationSecurityComponent } from './registration/security/registration-security.component';
import { RegistrationSummaryComponent } from './registration/summary/registration-summary.component';
import { LinkAccountComponent } from './registration/profile/linkaccount.component';
import { IdleComponent } from './idle/idle.component';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { ElectronicStatementComponent } from './electronic-statement.component';
import { AchUSBankStatementComponent } from './ach-us-bank-statement.component';
import { LineOfBusinessInformationComponent } from './form/line-of-business/line-of-business-information.component';
import { BorrowerDetailComponent } from './form/borrower/borrower-detail.component';
import { BorrowerInformationComponent } from './form/borrower/borrower-information.component';
import { ConfirmationComponent } from './form/confirmation/confirmation.component';
import { EntityInformationComponent } from './form/entity/entity-information.component';
import { FinancialInformationBalanceSheetSelectionComponent } from './form/balance-sheet/financial-information-balance-sheet-selection.component';
import { FinancialInformationBalanceSheetComponent } from './form/balance-sheet/financial-information-balance-sheet.component';
import { FinancialInformationIncomeComponent } from './form/income/financial-information-income.component';
import { FinancialInformationComponent } from './form/balance-sheet/financial-information.component';
import { FinancialSummaryComponent } from './form/balance-sheet/financial-summary.component';
import { OwnerDetailComponent } from './form/ownership/owner-detail.component';
import { OwnershipComponent } from './form/ownership/ownership.component';
import { RadioCompleteComponent } from './form/line-of-business/radio-complete.component';
import { ResetPasswordLandingComponent } from './form/reset-password/reset-password-landing.component';
import { SummaryAndSignComponent } from './form/summary/summary-and-sign.component';
import { SurveyComponent } from './form/confirmation/survey.component';
import { VendorProgramsComponent } from './form/program/vendor-programs.component';
import { VendorComponent } from './form/program/vendor.component';
import { NewLandingComponent } from './landing/new-landing.component';
import { HomeComponent } from './home/home.component';
import { HelpTipComponent } from './form/reusables/help-tip.component';
import { NumberInputComponent } from './form/reusables/number-input.component';
import { NavigationDashboardComponent } from './dashboard/account/navigation/navigation.component';
import { AccountPreferencesComponent } from './dashboard/preferences/account-preferences.component';
import { PreferencesContactComponent } from './dashboard/preferences/contact/preferences-contact.component';
import { PreferencesNameComponent } from './dashboard/preferences/name/preferences-name.component';
import { PreferencesPasswordComponent } from './dashboard/preferences/password/preferences-password.component';
import { PreferencesAlertsComponent } from './dashboard/preferences/alerts/preferences-alerts.component';
import { PreferencesSecurityComponent } from './dashboard/preferences/security/preferences-security.component';
import { ApplicationStatusSummaryComponent } from './dashboard/home/application-status-summary/application-status-summary.component';
import { DashboardHomeComponent } from './dashboard/home/dashboard-home.component';
import { UserDashboardComponent } from './dashboard/user-dashboard.component';
import { AccountSummaryComponent } from './dashboard/home/account-summary/account-summary.component';
import { PaymentsComponent } from './dashboard/account/payments/payments.component';
import { PayoffComponent } from './dashboard/account/payments/payoff.component';
import { PaymentNavigationComponent } from './dashboard/account/payments/payment-navigation.component';
import { AccountStatementsComponent } from './dashboard/account/statements/account-statements.component';
import { UserAccountGuard } from './services/user-account-guard';
import { AccountDetailsComponent } from './dashboard/account/account-details/account-details.component';
import { AccountTemplateComponent } from './dashboard/account/account-template.component';
import { PaymentHistoryComponent } from './dashboard/account/payments/payment-history.component';
import { SubCommitmentDetailsComponent } from './dashboard/account/account-details/subcommitment-details.component';
import { AmendmentComponent } from './dashboard/account/amendment/amendment.component';
import { AuthorizedPartyComponent } from './dashboard/account/authorized-party/authorized-party.component';
import { AuthorizedPartyAddFormComponent } from './dashboard/account/authorized-party/add-form/add-form.component';
import { AuthorizedPartyInProgressDetailComponent } from './dashboard/account/authorized-party/in-progress-detail/in-progress-detail.component';
import { AuthorizedPartyResponseComponent } from './dashboard/account/authorized-party/response/response.component';
import { TransactionReportComponent } from './dashboard/account/transaction-report/transaction-report.component';
import { EnvironmentBannerComponent } from './shared/environment-banner/environment-banner.component';
import { LoadingFeedbackComponent } from './shared/loading-feedback/loading-feedback.component';
import { SectionHeaderComponent } from './shared/section-header/section-header.component';
import { PreApprovalComponent } from './form/preapproval/preapproval.component';
import { EditSecurityQuestionsComponent } from './form/reusables/account/edit-security-questions.component';
import { ResetSecurityQuestionsComponent } from './form/reusables/account/reset-security-questions.component';
import { DoingBusinessAsComponent } from './form/entity/doing-business-as/doing-business-as.component';
import { IncreasedRiskCountriesComponent } from './form/increased-risk-countries/increased-risk-countries.component';
import { MessageCenterComponent } from './shared/message-center/message-center.component';
import { LookupService } from './services/lookup.service';
import { UserService } from './services/user.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { AuthenticatedHttp } from './services/authenticated-http.service';
import { CurrentApplicationService } from './services/current-application.service';
import { ApplicationHttpService } from './services/application-http.service';
import { CurrentRegistrationService } from './services/current-registration.service';
import { AccountHttpService } from './services/account-http.service';
import { DocumentService } from './services/document.service';
import { MessageCenterService } from './services/message-center.service';
import { VendorService } from './services/vendor.service';
import { AuthorizedPartyService } from './services/authorized-party.service';
import { FinancialSummaryService } from './services/financial-summary.service';
import { LoggingService } from './services/logging.service';
import { KeysPipe } from './pipes/keys.pipe';
import { SafeHtmlPipe } from './pipes/SafeHtml.pipe';
import { IfAngularCoreModule } from 'if-angular-core'
import { ModalDialogComponent } from './shared/modal-dialog/modal-dialog.component';
import { ChangeLogService } from './services/changelog.service';
import { AppSharedModule } from '../appshared/app-shared.module';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';


@NgModule({
  imports: [
    BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule, routing,
    MatDatepickerModule, MatInputModule, MatNativeDateModule, CheckboxModule, AccordionModule, DialogModule, TableModule,
    RadioButtonModule, DropdownModule, ButtonModule, TabMenuModule, ToastModule, InputTextareaModule, AutoCompleteModule, TextMaskModule,
    CalendarModule, MatSelectModule, MatRadioModule, MessageModule, MessagesModule, FlexLayoutModule, IfAngularCoreModule,
    AppSharedModule],       // module dependencies TODO:MFA
  exports: [ // TODO:MFA
    AchUSBankStatementComponent, ElectronicStatementComponent, TermsAndConditionsComponent,
    ReCaptchaComponent, KeysPipe, SafeHtmlPipe, FooterComponent, EnvironmentBannerComponent, MessageCenterComponent,
    ModalDialogComponent, LoadingFeedbackComponent, SectionHeaderComponent, ApplicationStatusSummaryComponent
  ],
  declarations: [
    AppComponent, IdleComponent, AddressComponent, AutocompleteComponent, ChangeApplicationTypeComponent, CombinedAddressComponent,
    ComplexQuestionComponent, ContactComponent, ContactPageComponent, CorporationComponent, CreditRequirements, DateBoxComponent,
    FooterComponent, HeaderComponent, FullNameComponent, LoginComponent, PasswordExpiredComponent, PhoneComponent, ReCaptchaComponent,
    ResetPasswordComponent, SecurityQuestionComponent, SideNavComponent, SimpleQuestionComponent, StateComponent, ZipComponent,
    AutoSelectDirective, CheckboxValidatorDirective, ComparisonValidatorDirective, EmailValidatorDirective, DateRangeValidatorDirective,
    NumberValidatorDirective, PasswordValidatorDirective, TermsAndConditionsComponent, ForceDecimalsDirective, NumberInputComponent,
    LineOfBusinessInformationComponent, BorrowerDetailComponent, ConfirmationComponent, EntityInformationComponent,
    FinancialInformationBalanceSheetComponent, FinancialInformationBalanceSheetSelectionComponent, FinancialInformationComponent,
    FinancialInformationIncomeComponent, FinancialSummaryComponent, OwnerDetailComponent, OwnershipComponent, RadioCompleteComponent,
    ResetPasswordLandingComponent, SummaryAndSignComponent, SurveyComponent, VendorProgramsComponent, AccountPreferencesComponent,
    ApplicationStatusSummaryComponent, UserDashboardComponent, DashboardHomeComponent, NavigationDashboardComponent,
    NewLandingComponent, BorrowerInformationComponent,
    HelpTipComponent, RegistrationComponent, RegistrationProfileComponent, RegistrationLinkAccountComponent, LinkAccountComponent,
    RegistrationNavigationComponent, RegistrationAgreementComponent, RegistrationSecurityComponent, RegistrationSummaryComponent,
    AccountSummaryComponent, PaymentsComponent, PaymentNavigationComponent, AccountDetailsComponent, AccountTemplateComponent,
    PaymentHistoryComponent, EnvironmentBannerComponent, ElectronicStatementComponent, AchUSBankStatementComponent,
    AccountStatementsComponent, SubCommitmentDetailsComponent, LoadingFeedbackComponent, SectionHeaderComponent, HomeComponent,
    PayoffComponent, PreferencesContactComponent, PreferencesPasswordComponent, PreferencesAlertsComponent, PreferencesSecurityComponent,
    AttachmentsComponent, PreferencesNameComponent, AutofocusDirective, AmendmentComponent, KeysPipe, MessageCenterComponent, SafeHtmlPipe,
    VendorComponent, AuthorizedPartyAddFormComponent, AuthorizedPartyInProgressDetailComponent, AuthorizedPartyResponseComponent,
    AuthorizedPartyComponent, TransactionReportComponent, PreApprovalComponent, EditSecurityQuestionsComponent, 
    ResetSecurityQuestionsComponent, DoingBusinessAsComponent, NoDuplicatesValidatorDirective, IncreasedRiskCountriesComponent, 
    ModalDialogComponent, DebounceClickDirective
  ],   // components and directives
  bootstrap: [
    AppComponent
  ],     // root component
  providers: [
    Idle, SimpleExpiry, { provide: IdleExpiry, useExisting: SimpleExpiry }, AuthorizationGuard, UserService, AuthService,
    AuthenticatedHttp, CurrentApplicationService, ApplicationHttpService, CurrentRegistrationService, AccountHttpService,
    StorageService, UserAccountGuard, DocumentService, MessageCenterService, VendorService, AuthorizedPartyService, FinancialSummaryService,
    LoggingService,ChangeLogService,CustomErrorHandler,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    LookupService
  ]                    // services
})
export class AppModule { }
