import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
  } from '@angular/core';
  import { Subject, Subscription } from 'rxjs';
  import { debounceTime } from 'rxjs/operators';
  
  // This directive can be used to ensure that double clicking a button will only invoke one call to the click method.
  @Directive({
    selector: '[rafDebounceClick]'
  })
  export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() 
    bounceTime: number = 500;
    @Output() 
    debounceClick: EventEmitter<any> = new EventEmitter();
  
    private clicks$ = new Subject();
    private subscription!: Subscription;
  
    constructor() {
    }
  
    ngOnInit() {
      this.subscription = this.clicks$
        .pipe(debounceTime(this.bounceTime))
        .subscribe((e) => this.debounceClick.emit(e));
    }
  
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
  
    @HostListener('click', ['$event'])
    clickEvent(event: Event) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks$.next(event);
    }
  }
  